var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted font-13 mb-0"}),_c('div',[_c('b-form-group',[_c('b-form-radio-group',{attrs:{"id":"radio-group-3","name":"radio-sub-component"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('b-form-radio',{attrs:{"name":"some-radios","value":"channelpartner"}},[_vm._v("ChannelPartner")]),_c('b-form-radio',{attrs:{"name":"some-radios1","value":"franchise"}},[_vm._v("Franchise")]),_c('b-form-radio',{attrs:{"name":"some-radios1","value":"ApproveOrRejectUsers"}},[_vm._v("Approve or Reject Users")])],1)],1),_c('good-table-column-search',{style:(_vm.type == 'channelpartner' ? '' : 'display:none'),attrs:{"moduleapi":((this.baseApi) + "/getpendingcplist"),"title":"KYC","columns":_vm.columns,"routerpath":"/master/crm/addchannelpartner","formOptions":_vm.formOptions,"kyc":true,"newPage":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
var getGridData = ref.getGridData;
return [_c('action',{attrs:{"row":row,"getGridData":getGridData}})]}},{key:"customField",fn:function(ref){
var props = ref.props;
return [(props.column.field == 'is_kyc')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(
                    props.row.is_kyc == 0
                      ? 'Pending'
                      : props.row.is_kyc == 1
                        ? 'Accepted'
                        : 'Rejected'
                  )}},[_vm._v(" "+_vm._s(props.row.is_kyc == 0 ? "Pending" : props.row.is_kyc == 1 ? "Accepted" : "Rejected")+" ")])],1):_vm._e()]}}])}),_c('good-table-column-search',{style:(_vm.type == 'franchise' ? '' : 'display:none'),attrs:{"moduleapi":((this.baseApi) + "/getpendingfrlist"),"title":"KYC","columns":_vm.columns,"routerpath":"/master/crm/addfranchisr","formOptions":_vm.formOptions,"kyc":true,"newPage":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                  var row = ref.row;
                  var getGridData = ref.getGridData;
return [_c('action',{attrs:{"row":row,"getGridData":getGridData}})]}},{key:"customField",fn:function(ref){
                  var props = ref.props;
return [(props.column.field == 'is_kyc')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(
                    props.row.is_kyc == 0
                      ? 'Pending'
                      : props.row.is_kyc == 1
                        ? 'Accepted'
                        : 'Rejected'
                  )}},[_vm._v(" "+_vm._s(props.row.is_kyc == 0 ? "Pending" : props.row.is_kyc == 1 ? "Accepted" : "Rejected")+" ")])],1):_vm._e()]}}])}),_c('good-table-column-search',{style:(_vm.type == 'ApproveOrRejectUsers' ? '' : 'display:none'),attrs:{"moduleapi":((this.baseApi) + "/getapprovedusers"),"title":"KYC","columns":_vm.columns.slice(0, _vm.columns.length - 1),"routerpath":"/master/crm/addfranchise","formOptions":_vm.formOptions,"kyc":true,"newPage":true,"useRowColors":true},scopedSlots:_vm._u([{key:"customField",fn:function(ref){
                  var props = ref.props;
return [(props.column.field == 'is_kyc')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(
                    props.row.is_kyc == 0
                      ? 'Pending'
                      : props.row.is_kyc == 1
                        ? 'Accepted'
                        : 'Rejected'
                  )}},[_vm._v(" "+_vm._s(props.row.is_kyc == 0 ? "Pending" : props.row.is_kyc == 1 ? "Accepted" : "Rejected")+" ")])],1):_vm._e()]}}])})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }